import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import globalFilter from './globalFilters'

import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase/config'
import { getUserOtherInfo } from './firebase/controllers/userController'

import { setPresence, infoConnected, updateOnAway } from '@/firebase/controllers/presenceController'
import { listenForAnnouncement } from "@/firebase/utils/scheduler";


document.querySelector('#loading').style.display = 'block';
let skilltechApp;

onAuthStateChanged(auth, async(user) => {

    // get user other info from firestore
    let authUser = null;
    if (user) {
        // if (await authIsAdmin(user)) { 
        // }
        let otherInfo = await getUserOtherInfo(user.uid);
        authUser = { credentials: user, otherInfo: otherInfo };
    }

    // save it to store
    await store.dispatch('setUser', authUser);

    // check user presence
    if (user) setPresence('online');
    infoConnected()
    updateOnAway()

    // listen for announcement if user is logged in
    listenForAnnouncement(user ? true : false);

    document.querySelector('#loading').style.display = 'none';

    // create vue app if not yet created
    if (!skilltechApp) {
        skilltechApp = createApp(App);
        skilltechApp.use(store).use(router).mount('#app');
        globalFilter(skilltechApp);
    }

});

// This is to retry failed cicd pipeline.
// 2nd try (develop)